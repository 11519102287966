<template>
  <el-dialog
      title="选择标签"
      :close-on-click-modal="true"
      :visible.sync="tagDialog"
      v-if="tagDialog"
      @close="closeDialog"
      width="50%"
      append-to-body>

    <el-input v-model="filterText" placeholder="搜索术语" style="width: 300px"></el-input>
    <el-tree
        class="filter-tree"
        node-key="id"
        :data="treeData"
        :props="defaultProps"
        :filter-node-method="filterNode"
        @node-click="choose"
        ref="tree"
        :highlight-current="true"
    >
    </el-tree>

    <el-button @click="assignedEntities">添加标签</el-button>
  </el-dialog>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  name: "chooseTag",
  data() {
    return {
      tagDialog: false,
      filterText: "",
      treeData: [],
      termGuid: '',
      subEquipmentHbaseName: '',
      hbaseTableGuid: '',
      defaultProps: {
        children: "children",
        label: "label",
      },
    }
  },
  // created() {
  //   this.instance = axios.create();
  //   this.instance({
  //     url: "/metadata/j_spring_security_check",
  //     method: "post",
  //     withCredentials: true,
  //     headers: {
  //       "Accept": "*/*",
  //       "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
  //     },
  //     data: qs.stringify({
  //       j_username: "admin",
  //       j_password: "admin",
  //     }),
  //   });
  // },
  methods: {
    async init(subEquipIdFromNode) {
      this.tagDialog = true;
      console.log(subEquipIdFromNode)
      const instance = axios.create();
      await instance({
        url: "/metadata/j_spring_security_check",
        method: "post",
        withCredentials: true,
        headers: {
          "Accept": "*/*",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        data: qs.stringify({
          j_username: "admin",
          j_password: "admin",
        }),
      });
      instance(
          {
            url: "/metadata/api/atlas/v2/glossary",
            method: "get",
            withCredentials: true,
            headers: {
              "Accept": "*/*",
              "Content-Type": "application/json",
            },
          }).then(({ data }) => {
            data.forEach((element) => {
              console.log(element);
              var node = {};
              var children = [];
              node["id"] = element.guid;
              node["label"] = element.name;

              element.terms.forEach((item) => {
                var childrenNode = {};
                childrenNode["id"] = item.termGuid;
                childrenNode["label"] = item.displayText;
                children.push(childrenNode);
              });
              node["children"] = children;
              this.treeData.push(node);
              // console.log(this.treeData);
            });
          }
      )

      this.getRequest("/subEquipmentManage/getSubEquipmentHbaseNameById?subEquipmentId=" + subEquipIdFromNode).then(resp => {
        if(resp) {
          console.log(resp)
          this.subEquipmentHbaseName = resp.data

          instance({
            url: "/metadata/api/atlas/v2/search/quick",
            method: "post",
            withCredentials: true,
            headers: {
              "Accept": "*/*",
              "Content-Type": "application/json"
            },
            data: JSON.stringify({
              query: this.subEquipmentHbaseName,
              typeName: "hbase_table",
              limit: 1
            }),
          }).then(resp => {
            if(resp) {
              this.hbaseTableGuid = resp.data.searchResults.entities[0].guid
              console.log(this.hbaseTableGuid)
            }
          })
        }
      })
    },
    closeDialog() {
      this.tagDialog = false;
      this.treeData = []
    },
    // 过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    choose(data, node) {
      console.log(data)
      console.log(node)
      this.$refs.tree.setCurrentKey(data.id);
      if (!data.children) {
        this.termGuid = data.id
      }
      // const instance = axios.create();
      // instance({
      //   url: "/metadata/atlas/v2/search/quick",
      //   method: "get",
      //   withCredentials: true,
      //   headers: {
      //     "Accept": "*/*",
      //   },
      //   data: qs.stringify({
      //     query: "admin",
      //     typeName: "hbase_table",
      //   }),
      // });
    },
    assignedEntities() {
      if(this.termGuid != ''){
        const instance = axios.create();
        instance({
          url: "/metadata/api/atlas/v2/glossary/terms/" + this.termGuid + "/assignedEntities",
          method: "post",
          withCredentials: true,
          headers: {
            "Accept": "application/json,text/javascript,*/*;q=0.01,",
            "Content-Type": "application/json"
          },
          data: JSON.stringify([
            {
              guid: this.hbaseTableGuid
            }
          ])
        }).then((resp) => {
          if (resp) {
            this.$message({
              type: "success",
              message: "术语添加成功",
            });
          }
        }).catch(() => {
          this.$message({
            type: "error",
            message: "术语添加失败",
          });
        });;
      } else {
        this.$message({
          type: "error",
          message: "未选择术语",
        });
      }
      // this.closeDialog();
    }
  }

}
</script>

<style scoped>

</style>
